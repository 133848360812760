import React from "react"
import styled from "styled-components"

const StyledNarrow = styled.div`
  max-width: 1200px;
  margin: auto;
`

const Narrow = props => {
  return <StyledNarrow>{props.children}</StyledNarrow>
}

export default Narrow
