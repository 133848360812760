import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import IndexSection from "../components/index-section"
import { Helmet } from "react-helmet"

const StyledIndexPage = styled.div``

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Agnes Suto</title>
      </Helmet>
      <StyledIndexPage>
        <IndexSection context="myndir" />
        <IndexSection context="teikningar" />
      </StyledIndexPage>
    </Layout>
  )
}

export default IndexPage
