import React from "react"
import styled from "styled-components"

const StyledGrid = styled.div`
  padding: 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Grid = props => {
  return <StyledGrid>{props.children}</StyledGrid>
}

export default Grid
