import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Button from "./button"
import { useStaticQuery, graphql, Link } from "gatsby"
import Grid from "./grid"
import GalleryItem from "./gallery-item"
import Narrow from "./narrow"

const StyledIndexSection = styled.div`
  text-align: center;
  padding-bottom: 3rem;
  h1 {
    margin: 3rem 0 0 0;
    text-transform: uppercase;
    font-weight: 300;
  }
`

const IndexSection = props => {
  const [active, setActive] = useState(null)

  const query = useStaticQuery(graphql`
    query {
      contentfulMyndir {
        header
        frontPageImages {
          id
          lowRes {
            fluid {
              src
              ...GatsbyContentfulFluid
            }
          }
          fullRes {
            fluid {
              src
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      contentfulTeikningar {
        header
        frontPageImages {
          id
          lowRes {
            fluid {
              src
              ...GatsbyContentfulFluid
            }
          }
          fullRes {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)

  const toggleActive = index => {
    if (index !== active) {
      setActive(index)
    } else {
      setActive(null)
    }
  }

  let frontPageImages

  switch (props.context) {
    case "myndir":
      frontPageImages = query.contentfulMyndir.frontPageImages

      break
    case "teikningar":
      frontPageImages = query.contentfulTeikningar.frontPageImages

      break

    default:
      break
  }

  const header =
    props.context === "myndir"
      ? query.contentfulMyndir.header
      : query.contentfulTeikningar.header

  return (
    <StyledIndexSection>
      <h1>{header}</h1>
      <Narrow>
        <div className="index-page-images">
          <Grid>
            {frontPageImages.map((image, index) => (
              <GalleryItem
                active={active === index}
                image={image}
                alt=""
                key={image.id}
                onClick={() => {
                  toggleActive(index)
                }}
              />
              // <Img fluid={image.lowRes.fluid} alt="" style={{ zIndex: -1 }} />
            ))}
          </Grid>
        </div>
        <Link to={props.context === "myndir" ? "/myndir" : "/teikningar"}>
          <Button>Skoða meira</Button>
        </Link>
      </Narrow>
    </StyledIndexSection>
  )
}

export default IndexSection
