import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const StyledGalleryItem = styled.div`
  cursor: pointer;
  .full-res {
    display: none;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 400ms ease-in-out;
    &-inner {
      margin: auto;
      min-width: 40vw;
    }
  }
  &:hover {
  }
  &.active {
    .full-res {
      z-index: 100;
      pointer-events: initial;
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    .full-res-inner {
      min-width: 95vw;
    }
  }
`

const GalleryItem = props => {
  return (
    <StyledGalleryItem
      onClick={props.onClick}
      className={props.active ? "active" : ""}
    >
      <Img
        fluid={props.image.lowRes.fluid}
        alt={props.alt}
        style={{ zIndex: -1 }}
      />
      <div className={`full-res`}>
        <div className="full-res-inner">
          <Img
            fluid={props.image.fullRes.fluid}
            alt={props.alt}
            style={{
              objectFit: "contain",
            }}
          />
        </div>
      </div>
    </StyledGalleryItem>
  )
}

export default GalleryItem
